/* eslint-disable import/no-cycle */
// @flow
import React from 'react';
import { styled } from '@linaria/react';
import {
    node, string, bool, func,
} from 'prop-types';
import { cx } from '@linaria/core';
import { themeObj } from '../GlobalStyle';
import LoadingSpinner from '../LoadingSpinner';

const StyledButton = styled.button`
    background-color: ${themeObj.colors.primary};
    min-width:170px;
    border: none;
    margin:8px
    border-radius: 12px;
    box-shadow: 0px 16px 40px 0px rgba(241, 241, 241, 0.15);
    color: ${themeObj.colors.white};
    cursor: pointer;
    font-family: ${themeObj.fonts.regular};
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: 22px;
    padding: 20px 40px;
    position: relative;
    text-transform: uppercase;
    transition: background-color 0.3s;
    &:hover {
        background: ${themeObj.colors.primaryDark};
    }
    &:active {
        background: ${themeObj.colors.primaryDark};
        box-shadow: 0px 16px 20px rgba(241, 241, 241, 0.15),
            inset 0px 1px 8px rgba(0, 0, 0, 0.15),
            inset 0px -1px 8px rgba(0, 0, 0, 0.15);
    }
    white-space: nowrap;
    
    &.secondary {
        border: 1px solid ${themeObj.colors.primary};
        background-color: ${themeObj.colors.greys.lightest};
        color: ${themeObj.colors.primary};
        &:hover{
            background-color: ${themeObj.colors.white};
            color: ${themeObj.colors.primary};
        }
        &:active{
            background-color: ${themeObj.colors.white};
            color: ${themeObj.colors.primary};
        }
    }
    &.loading,
    &:disabled,
    &[disabled] {
        background-color: ${themeObj.colors.greys.light};
        color: ${themeObj.colors.greys.dark};
        &:hover,active{
            background-color: ${themeObj.colors.greys.light};
            color: ${themeObj.colors.greys.dark};
        }
    }
    &.loading{
        color: transparent !important;
    }
    `;

const Spinner = styled(LoadingSpinner)`
position: relative;
width: 100%;
margin: -8px 0;
height: 16px;
display: flex;
    align-content: center;
    justify-content: center;
    div{
        width: 32px;
        height: 32px;
        margin: 4px;
        border-width: 4px;
        top: 0;
    }
    `;

const Button = ({
    theme,
    children, onClick, variant, disabled, isLoading, className, style, ...rest
}) => {
    const classes = cx(variant, isLoading && 'loading', className);
    return (
        <StyledButton
            onClick={onClick}
            disabled={disabled || isLoading}
            className={classes}
            style={style}
            {...rest}
        >
            {isLoading && <Spinner />}
            {children}
        </StyledButton>
    );
};

export default Button;

Button.propTypes = {
    children: node.isRequired,
    variant: string,
    disabled: bool,
    isLoading: bool,
    onClick: func,
};

Button.defaultProps = {
    variant: 'primary',
    disabled: false,
    isLoading: false,
    onClick: undefined,
};
