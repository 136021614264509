import React from 'react';
import { themeObj } from '../../GlobalStyle';

export default ({ color = themeObj.colors.primary }) => (
    <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="19.5" cy="19.5" r="19.5" fill="#F6F7FA" />
        <g clipPath="url(#clip0_50_2765)">
            <path d="M23.7166 16.2067C23.3062 15.928 22.7523 16.0166 22.4038 16.3716L18.1044 20.7542L16.5976 19.2173C16.2491 18.862 15.6952 18.7733 15.2848 19.0524C15.1567 19.1375 15.0489 19.2507 14.9691 19.3837C14.8894 19.5167 14.8398 19.6662 14.8239 19.8212C14.808 19.9762 14.8263 20.1329 14.8773 20.2799C14.9284 20.4268 15.011 20.5602 15.119 20.6705L17.3722 22.968C17.3988 22.9951 17.4269 23.0207 17.4563 23.0446C17.6534 23.2085 17.9031 23.292 18.157 23.2789C18.411 23.2658 18.6511 23.157 18.831 22.9737L23.8808 17.8231C23.9886 17.713 24.071 17.5797 24.122 17.433C24.173 17.2863 24.1913 17.1299 24.1756 16.9751C24.1599 16.8203 24.1105 16.671 24.0311 16.5381C23.9517 16.4052 23.8443 16.292 23.7166 16.2067Z" fill={color} />
            <path d="M10 19.9418V19.3647C10.053 18.9034 10.0809 18.4379 10.1617 17.9812C10.5741 15.6198 11.6908 13.6637 13.5203 12.1627C15.7533 10.3307 18.313 9.66406 21.1366 10.1542C23.4481 10.5553 25.3883 11.6882 26.8204 13.5759C29.315 16.8623 29.6865 20.4505 27.836 24.15C26.3959 27.0308 23.9854 28.6835 20.8459 29.2009C20.476 29.2619 20.0992 29.2833 19.7256 29.3204H19.2404C19.1816 29.3085 19.1222 29.2995 19.0625 29.2936C17.4825 29.2227 16.0027 28.7899 14.6556 27.9509C12.1994 26.4236 10.6934 24.2123 10.1646 21.3224C10.0801 20.8685 10.053 20.4027 10 19.9418ZM27.2316 19.6532C27.2316 15.2933 23.7598 11.755 19.483 11.7554C15.2063 11.7558 11.7353 15.2945 11.7353 19.6541C11.7353 24.0136 15.2083 27.5519 19.4838 27.5519C23.7594 27.5519 27.234 24.0119 27.2316 19.6528V19.6532Z" fill={color} />
        </g>
        <defs>
            <clipPath id="clip0_50_2765">
                <rect width="19" height="19.322" fill="white" transform="translate(10 10)" />
            </clipPath>
        </defs>
    </svg>

);
