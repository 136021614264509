import axiosClient from '../apiClient';
import redirectOn401 from '../../utils/redirectOn401';
import getISODate from '../../utils/getISODate';
/* global DD_RUM */

export default () => {
    const priorDate = getISODate(-30);
    return axiosClient(localStorage.access_token).get(`/requests?from_date=${priorDate}`)
        .then(({ data }) => data)
        .catch((err) => {
            redirectOn401(err.response.status);
            DD_RUM.addAction('getRequestsError', { err });
        });
};
