import axiosClient from '../apiClient';
import redirectOn401 from '../../utils/redirectOn401';
/* global DD_RUM */

export default (submissionId) => axiosClient(localStorage.access_token).get(`/submissions/${submissionId}`)
    .then(({ data }) => data)
    .catch((err) => {
        redirectOn401(err.response.status);
        DD_RUM.addAction('getSubmissionByIdError', { err });
    });
