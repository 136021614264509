/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { styled } from '@linaria/react';
import { Table, NavLink } from 'tm-library';
import { Completed as CompletedIcon } from 'tm-library/src/components/Icons/';
import {
    Background, Title, TopSection, EmptyList,
} from '../TabScreenTemplate';
import formatDateTime from '../../utils/formatDateTime';
import filterAndSortRequests from '../../utils/filterAndSortRequests';
import createCifLinkMarkdown from '../../utils/createCifLinkMarkdown';
import ReviewOutcome from '../../components/ReviewOutcome';
import { transformToReasonValue, reasonLookup } from '../../utils/resubmissionLookup';
import Search from '../../components/Search';
import useSearch from '../../hooks/useSearch';

const CompletedTable = styled(Table)`
    th:nth-child(n+4):nth-child(-n+7),
    td:nth-child(n+4):nth-child(-n+7) {
        text-align: left;
    }
`;

export const modelCompletedTableSubmissions = (submissions) => {
    if (!submissions) return [];
    const modelledSubmissions = [];

    submissions.forEach((submission) => {
        const {
            submission_id, document_request_id, created_date_time, document_type,
            sub_document_type, cifkey, forename, surname, review_outcome, review_outcome_reason, reviewer, review_outcome_other_reason,
        } = submission;
        const [date, time] = formatDateTime(created_date_time);
        const rootUrl = window.location.href.split('?')[0];

        const documentType = document_type.toLowerCase() === 'other'
            ? `${document_type} (${sub_document_type})`
            : `${document_type}`;

        const reason = transformToReasonValue(review_outcome_reason, reasonLookup, review_outcome_other_reason);
        const modelledSubmission = {
            dateRequested: date,
            time,
            customerCIF: createCifLinkMarkdown(cifkey, `${rootUrl}?submissionId=${submission_id}&previousTab=Completed`),
            customerName: `${forename} ${surname}`,
            documentType,
            reviewer,
            decision: <ReviewOutcome outcome={review_outcome.toUpperCase()} reason={reason.slice(0, reason.indexOf(' - '))} />,
            requestID: document_request_id,
        };
        modelledSubmissions.push(modelledSubmission);
    });
    return modelledSubmissions;
};

const Completed = ({ submissions }) => {
    const [completedList, setCompletedList] = useState([]);
    const headings = ['Date received', 'Time', 'Customer CIF', 'Customer name', 'Document type', 'Reviewer', 'Decision', 'Request ID'];
    useEffect(() => {
        if (!submissions || submissions?.length < 1) return;
        const filteredAndSortedSubmissions = filterAndSortRequests(submissions, 'Completed');
        const modelledSubmissions = modelCompletedTableSubmissions(filteredAndSortedSubmissions);
        setCompletedList(modelledSubmissions);
    }, [submissions]);

    const {
        searchTerm, setSearchTerm, filteredList, clearSearch,
    } = useSearch(completedList);

    return (
        <Background>
            <TopSection>
                <Title>
                    <CompletedIcon />
                    Completed
                </Title>
                <Search clearSearch={clearSearch} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            </TopSection>
            {searchTerm
                ? (
                    <>
                        <CompletedTable headings={headings} body={filteredList} />
                        {filteredList?.length < 1
                            && (
                                <>
                                    <EmptyList>
                                        No results found
                                        <NavLink onClick={clearSearch}>Clear search</NavLink>
                                    </EmptyList>
                                </>
                            )}
                    </>
                )
                : (
                    <>
                        <CompletedTable headings={headings} body={completedList} />
                        {completedList?.length < 1 && <EmptyList>No completed requests</EmptyList>}
                    </>
                )}
        </Background>
    );
};

export default Completed;
