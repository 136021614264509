import React from 'react';
import { styled } from '@linaria/react';
import { themeObj } from 'tm-library';
import { func } from 'prop-types';
import Button from '../Button';

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.65);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
`;
const PopupContent = styled.div`
    position: relative;
    padding: 34px 59px;
    width: 100%;
    max-width: 642px;
    max-height: 80%;
    background-color: ${themeObj.colors.white};
    border-radius: 12px;
    display:grid;
    grid-template-columns: 60% auto 20%;
    overflow: auto;
`;
const ButtonWrapper = styled.div`
    justify-items: center;
    align-items: center;
    grid-column: 3;
`;
const Popup = ({ closePopup, children }) => (
    <Overlay data-testid="popup-overlay">
        <PopupContent className="hide-scroll" data-testid="popup-content">
            {children}
            <ButtonWrapper>
                <Button onClick={() => closePopup()} variant="secondary">X Close</Button>
            </ButtonWrapper>
        </PopupContent>
    </Overlay>
);

Popup.propTypes = {
    closePopup: func.isRequired,
};
export default Popup;
