import React from 'react';
import { styled } from '@linaria/react';
import { themeObj, SmallBody } from 'tm-library/src';

const Error = styled(SmallBody)`
    color: ${themeObj.colors.red};
    margin:0;
`;

const ErrorMessage = ({ className, children }) => <Error className={className}>{children}</Error>;

export default ErrorMessage;
