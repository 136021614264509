import { styled } from '@linaria/react';
import { themeObj } from '../GlobalStyle';

export const H1 = styled.h1`
    font-family: ${themeObj.fonts.medium};
    margin: 16px 0;
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: -2.5px;
    @media (min-width: 768px) {
        font-size: 56px;
        line-height: 64px;
    }
`;
H1.displayName = 'H1';

export const H2 = styled.h2`
    font-family: ${themeObj.fonts.medium};
    margin: 16px 0;
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.5px;
`;

H2.displayName = 'H2';

export const H3 = styled.h3`
    margin: 16px 0;
    font-family: ${themeObj.fonts.medium};
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -1px;

    @media (min-width: 768px) {
        font-size: 32px;
        line-height: 40px;
        letter-spacing: -1.5px;
    }
`;
H3.displayName = 'H3';

export const H4 = styled.h4`
    margin: 16px 0;
    font-family: ${themeObj.fonts.medium};
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -1px;

    @media (min-width: 768px) {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -1px;
    }
`;
H4.displayName = 'H4';

export const Body = styled.p`
    font-size: 18px;
    line-height: 27px;
    letter-spacing: -0.5px;
    margin: 16px 0;
`;
Body.displayName = 'Body';

export const SmallBody = styled.p`
    font-size: 14px;
    line-height: 23px;
    letter-spacing: -0.5px;
    margin: 16px 0;
`;
SmallBody.displayName = 'SmallBody';

export const Mark = styled.mark`
    color: ${themeObj.colors.primary};
    background-color:transparent;
`;
Mark.displayName = 'Mark';
