import React from 'react';
import { styled } from '@linaria/react';
import { ValidationError } from '../../../../tm-library/src/components/Icons';
/* global DD_RUM */

const StyledLink = styled.a` 
    display: flex;
    justify-content: space-between;
`;

const OpenFileLog = (id, name) => {
    const userId = localStorage.getItem('userId');
    DD_RUM.addAction('OpenFile', { id, name, userId });
};
const rootUrl = window.location.href.split('?')[0];
const UploadedFileItem = ({ listHasError, listItem }) => {
    const { id, name, hasError } = listItem;
    const displayFile = (
        <>
            {hasError && (<ValidationError />)}
            {name}
        </>
    );
    return (listHasError ? <div>{displayFile}</div> : <StyledLink href={`${rootUrl}?fileId=${id}`} target="_blank" onClick={() => OpenFileLog(id, name)}>{displayFile}</StyledLink>);
};

export default UploadedFileItem;
