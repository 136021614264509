import React from 'react';
import { styled } from '@linaria/react';
import { Body } from 'tm-library/src/components/Typography/';
import { themeObj } from 'tm-library/src/components/GlobalStyle';
import { cx } from '@linaria/core';
import {
    string, func, shape, arrayOf,
} from 'prop-types';

const StyledNavigation = styled.nav`
    display:flex;
    gap:12px;
    padding-top: 34px;
`;

const Tab = styled.div`
    width: 185px;
    height: 59px;
    left: 232px;
    top: 63px;
    background: linear-gradient(180deg, #FFFFFF 75.42%, #F4F4F4 100%);
    border: 2px solid #F2F2F2;
    box-sizing: border-box;
    border-radius: 12px 12px 0px 0px;
    color: ${themeObj.colors.greys.light};
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    &:hover{
        cursor :pointer;
    }
}
    &.active-tab{
        background: ${themeObj.colors.white};
        color: ${themeObj.colors.greys.darkest};
        border-bottom: 2px solid ${themeObj.colors.primary};
    }
`;

const TabText = styled(Body)`
    font-size: 18px;
`;

const NavMenu = ({ menuItems, activeTab, setActiveTab }) => (
    <StyledNavigation>
        {menuItems.map(({ name, Icon }) => (
            <Tab
                key={`menu-item-${name}`}
                className={cx(activeTab === name && 'active-tab')}
                onClick={() => {
                    setActiveTab(name);
                }}
            >
                <Icon color={activeTab === name ? themeObj.colors.primary : themeObj.colors.greys.light} />
                <TabText>
                    {name}
                </TabText>
            </Tab>
        ))}

    </StyledNavigation>
);

export default NavMenu;

NavMenu.propTypes = {
    menuItems: arrayOf(shape({
        name: string,
        Icon: func,
        Screen: func,
    })).isRequired,
    activeTab: string.isRequired,
    setActiveTab: func.isRequired,

};
