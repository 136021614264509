/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { styled } from '@linaria/react';
import { arrayOf, string, object } from 'prop-types';
import { themeObj } from '../GlobalStyle';
import parseLink from '../../utils/parseLink';

const StyledTable = styled.table`
    border-collapse: collapse;
    font-size: 16px;
    letter-spacing: -0.5px;
`;

const HeadingsRow = styled.tr`
    background-color:${themeObj.colors.white};
    font-weight:bold;
    th {
        padding: 16px;
        font-weight:bold;
    }
`;

const Row = styled.tr`
    &:nth-child(odd) { background-color:${themeObj.colors.greys.lightest};}
    &:nth-child(even) { background-color:${themeObj.colors.white};}
`;

const Cell = styled.td`
    padding: 16px;
    white-space: nowrap; 
    border: 1px solid ${themeObj.colors.greys.light};
    text-align: center;
`;

const Table = ({
    headings, body, className, style,
}) => (
    <StyledTable className={className} style={style}>
        {headings?.length > 0 && (
            <thead>
                <HeadingsRow>
                    {headings.map((heading) => (
                        <th key={heading}>{heading}</th>
                    ))}
                </HeadingsRow>
            </thead>
        )}
        {
            body?.length > 0
            && (
                <tbody>
                    {body.map((rowContent, i) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Row key={`row-${i}`}>
                            {
                                Object.keys(rowContent).map((key) => {
                                    const markdownRegex = /^\[(...+|)\]\((?!javascript:)\S+\)$/i;
                                    let cellContent = rowContent[key];
                                    if (markdownRegex.test(rowContent[key])) {
                                        cellContent = parseLink(rowContent[key]);
                                    }
                                    return <Cell key={`${key}-${rowContent[key]}`}>{cellContent}</Cell>;
                                })
                            }
                        </Row>
                    ))}
                </tbody>
            )
        }
    </StyledTable>
);

export default Table;

Table.propTypes = {
    headings: arrayOf(string),
    body: arrayOf(object),
};

Table.defaultProps = {
    headings: [],
    body: [],
};
