import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {
    themeObj, globals, ThemeProvider, Head,
} from 'tm-library';
import AgentInterface from './views/AgentInterface';
import ErrorView from './views/ErrorView';

function App() {
    return (
        <ThemeProvider theme={themeObj}>
            <div className={globals}>
                <Head title="Document Exchange Admin" packageName="admin" />
                <Router>
                    <Routes>
                        <Route path="/" element={<AgentInterface />} />
                        <Route path="/error" element={<ErrorView />} />
                    </Routes>
                </Router>
            </div>
        </ThemeProvider>
    );
}

export default App;
