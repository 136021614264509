import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { styled } from '@linaria/react';
import { Inbox as InboxIcon, Waiting as WaitingIcon, Completed as CompletedIcon } from 'tm-library/src/components/Icons/';
import { H2, AlignCenter } from 'tm-library';
import Header from './Header';
import Inbox from './Inbox';
import Completed from './Completed';
import { Waiting } from './Waiting';
import SubmissionDetail from './SubmissionDetail';
import getValidTokens from '../actions/getValidTokens';
import getSubmissions from '../apis/getSubmissions';
import getRequests from '../apis/getRequests';
import UploadedFile from './UploadedFile';
import NavMenu from '../components/NavMenu';
import redirectHome from '../utils/redirectHome';

const fetchRequestsAndSubmissions = () => Promise.all([getRequests(), getSubmissions()])
    .then(([requests, submissions]) => ({ requests, submissions }));

const StyledContainer = styled.div`
    padding-bottom: 150px;
`;

const AgentInterface = () => {
    const [viewTableData, setViewTableData] = useState([]);
    const [searchParams] = useSearchParams();
    const [activeTab, setActiveTab] = useState('Inbox');
    const [refreshTab, setRefreshTab] = useState(false);
    const [viewDetailSubmissionId, setViewDetailSubmissionId] = useState('');
    const [viewUploadedFileId, setViewUploadedFileId] = useState('');
    const navigate = useNavigate();
    const code = searchParams.get('code');
    const submissionId = searchParams.get('submissionId');
    const fileId = searchParams.get('fileId');
    if (!code && !localStorage.access_token) redirectHome();

    useEffect(() => {
        if (code) {
            const onTokenSuccess = () => navigate('./');
            const onTokenError = () => navigate('/error');
            getValidTokens(code, onTokenSuccess, onTokenError);
        }
    }, []);

    useEffect(() => {
        if (submissionId || fileId) {
            setActiveTab('');
            setViewDetailSubmissionId(submissionId);
            setViewUploadedFileId(fileId);
        }
    }, [submissionId, fileId]);

    useEffect(() => {
        if ((!code && localStorage.access_token && activeTab !== '') || (!code && localStorage.access_token) || refreshTab) {
            fetchRequestsAndSubmissions()
                .then((requestsAndSubmissions) => {
                    setViewTableData(requestsAndSubmissions);
                    setRefreshTab(false);
                });
        }
    }, [code, activeTab, refreshTab]);

    const menuItems = [
        {
            name: 'Inbox',
            Icon: InboxIcon,
            Screen: Inbox,
        },
        {
            name: 'Waiting',
            Icon: WaitingIcon,
            Screen: Waiting,
        },
        {
            name: 'Completed',
            Icon: CompletedIcon,
            Screen: Completed,
        },
    ];

    const submissionDetailBackClick = (previousTab) => {
        setActiveTab(previousTab);
        setViewDetailSubmissionId('');
    };

    if (!code && localStorage.access_token) {
        let Screen;
        if (viewDetailSubmissionId && !activeTab) {
            Screen = (
                <SubmissionDetail
                    onBackClick={submissionDetailBackClick}
                    submissionId={viewDetailSubmissionId}
                />
            );
        } else if (viewUploadedFileId && !activeTab) {
            return <UploadedFile fileId={viewUploadedFileId} />;
        } else {
            const ScreenComponent = menuItems.find(({ name }) => name === activeTab)?.Screen;
            Screen = (
                <ScreenComponent
                    submissions={viewTableData?.submissions}
                    requests={viewTableData?.requests}
                />
            );
        }
        return (
            <StyledContainer>
                <Header activeTab={activeTab} setActiveTab={setActiveTab} setRefreshTab={setRefreshTab}>
                    <NavMenu menuItems={menuItems} activeTab={activeTab} setActiveTab={setActiveTab} />
                </Header>
                {Screen}
            </StyledContainer>
        );
    }

    return <AlignCenter padding="60px"><H2>Loading...</H2></AlignCenter>;
};

export default AgentInterface;
