import getTokens from '../getTokens';
import validateToken from '../validateToken';

/* global DD_RUM */
const getValidTokens = (code, onSuccess, onError) => {
    let accessToken;
    getTokens(code)
        .then(({ data }) => {
            accessToken = data.access_token;
            return validateToken(data.id_token);
        })
        .then(({ payload }) => {
            const [identity] = payload.identities;
            localStorage.setItem('userId', identity.userId);
            localStorage.setItem('access_token', accessToken);
            onSuccess();
        })
        .catch((err) => {
            DD_RUM.addAction('onGetValidTokens', { err });
            onError();
        });
};

export default getValidTokens;
