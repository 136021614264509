/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { styled } from '@linaria/react';
import { Table, NavLink } from 'tm-library';
import { Waiting as WaitingIcon } from 'tm-library/src/components/Icons/';
import formatDateTime from '../../utils/formatDateTime';
import {
    Background, Title, TopSection, EmptyList,
} from '../TabScreenTemplate';
import filterAndSortRequests from '../../utils/filterAndSortRequests';
import Search from '../../components/Search';
import useSearch from '../../hooks/useSearch';

const WaitingTable = styled(Table)`
    th:nth-child(n+4):nth-child(-n+5),
    td:nth-child(n+4):nth-child(-n+5) {
        text-align: left;
    }
`;

export const modelWaitingTableRequests = (requests) => {
    if (!requests) return [];
    const modelledRequests = [];
    requests.forEach((request) => {
        const {
            request_id, created_date_time, document_type, sub_document_type, recipient,
        } = request;
        const { cifkey, forename, surname } = recipient;
        const [date, time] = formatDateTime(created_date_time);

        const documentType = document_type.toLowerCase() === 'other'
            ? `${document_type} (${sub_document_type})`
            : `${document_type}`;

        const modelledRequest = {
            dateRequested: date,
            time,
            customerCIF: cifkey,
            customerName: `${forename} ${surname}`,
            documentType,
            requestID: request_id,
        };
        modelledRequests.push(modelledRequest);
    });
    return modelledRequests;
};

export const Waiting = ({ requests }) => {
    const [waitingList, setWaitingList] = useState([]);
    const headings = ['Date requested', 'Time', 'Customer CIF', 'Customer name', 'Document type', 'Request ID'];

    useEffect(() => {
        if (!requests || requests?.length < 1) return;
        const filteredAndSortedRequests = filterAndSortRequests(requests, 'Waiting');
        const modelledRequests = modelWaitingTableRequests(filteredAndSortedRequests);
        setWaitingList(modelledRequests);
    }, [requests]);

    const {
        searchTerm, setSearchTerm, filteredList, clearSearch,
    } = useSearch(waitingList);

    return (
        <Background>
            <TopSection>
                <Title>
                    <WaitingIcon />
                    Waiting
                </Title>
                <Search clearSearch={clearSearch} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            </TopSection>
            {searchTerm
                ? (
                    <>
                        <WaitingTable headings={headings} body={filteredList} />
                        {filteredList?.length < 1
                            && (
                                <>
                                    <EmptyList>
                                        No results found
                                        <NavLink onClick={clearSearch}>Clear search</NavLink>
                                    </EmptyList>
                                </>
                            )}
                    </>
                )
                : (
                    <>
                        <WaitingTable headings={headings} body={waitingList} />
                        {waitingList.length < 1 && <EmptyList>Nothing currently waiting</EmptyList>}
                    </>
                )}
        </Background>
    );
};

export default Waiting;
