import React, { useState, useEffect } from 'react';
import { styled } from '@linaria/react';
import {
    Body, themeObj,
} from 'tm-library';
import SuccessTick from 'tm-library/src/components/Icons/SuccessTickAlt';
import Popup from '../../components/Popup';
import NewDocumentRequestForm from '../NewDocumentRequestForm';
import Button from '../../components/Button';

const StyledHeader = styled.header`
    display:grid;
    grid-template-columns: 576px auto auto;
    padding: 36px 38px 0 0;
    margin: 0 38px -38px;
    margin-bottom: -38px;
`;

const ButtonWrapper = styled.div`
    grid-column: 3 / span 1;
    align-self: center;
    justify-self: right;
    padding-bottom: 15px;
`;

const StyledButton = styled(Button)`
    width: 166px;
`;

const NotificationBox = styled.div`
    grid-column: 1 / 4;
    border: 1px solid ${themeObj.colors.green}; 
    color: ${themeObj.colors.green};
    padding: 9px;
    display: flex;
    align-items: center;
    opacity:1;
    @keyframes fade-out{
        90% {opacity:1};
        100% {opacity:0;}
    } 
    animation: fade-out 5s;
    margin-bottom: 13px;
    width:100%;
`;
const NotificationPadding = styled.div`
    padding-top: 60px;
    width 100%;  
    grid-column: 1/4;
 `;
const NotificationText = styled(Body)`
    font-size: 16px;
    margin: auto;
    margin-left: 47px;
`;

const NotificationTitle = styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
    svg{
        padding-right:12px;
    }
`;

const Header = ({
    children, activeTab, setActiveTab, setRefreshTab,
}) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [showSuccessNotification, showSuccessfulNewDocNotification] = useState(false);
    // set to 4990 to prevent race condition between animation and timer
    const successfulRequestNotificationTimerDelay = 4990;
    const [requestId, setRequestId] = useState('');
    useEffect(() => {
        const timer = () => {
            setTimeout(() => {
                showSuccessfulNewDocNotification(false);
            }, successfulRequestNotificationTimerDelay);
        };
        if (showSuccessNotification) {
            timer();
        }
        return () => {
            clearTimeout(timer);
        };
    }, [showSuccessNotification]);

    const closePopup = () => {
        setIsPopupOpen(false);
    };

    const onRequestSubmit = (rid) => {
        closePopup();
        setRequestId(rid);
        showSuccessfulNewDocNotification(true);

        if (activeTab === 'Waiting') {
            setRefreshTab(true);
        } else {
            setActiveTab('Waiting');
        }
    };

    return (
        <>
            <StyledHeader>
                {showSuccessNotification ? (
                    <NotificationBox>
                        <NotificationTitle>
                            <SuccessTick />
                            <strong>New request success</strong>
                        </NotificationTitle>
                        <NotificationText>
                            {`Request Id - ${requestId}`}
                        </NotificationText>
                    </NotificationBox>
                ) : <NotificationPadding />}
                {children}
                <ButtonWrapper>
                    <StyledButton onClick={() => setIsPopupOpen(true)}>+ New Request</StyledButton>
                </ButtonWrapper>
            </StyledHeader>
            {isPopupOpen && (
                <Popup closePopup={closePopup}>
                    <NewDocumentRequestForm onSuccess={onRequestSubmit} />
                </Popup>
            )}
        </>
    );
};

export default Header;
