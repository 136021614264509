export const reasonLookup = [
    {
        key: 'AddressNotMatching',
        displayTitle: 'ADDRESS',
        displayValue: 'Address not matching',
    },
    {
        key: 'ImageNotClear',
        displayTitle: 'IMAGE',
        displayValue: 'Image not clear',
    },
    {
        key: 'DetailsHidden',
        displayTitle: 'HIDDEN',
        displayValue: 'Details hidden',
    },
    {
        key: 'Other',
        displayTitle: 'OTHER',
        displayValue: 'Other',
    },
];

export const reviewOutcomes = {
    Rejected: 'REJECTED',
    Approved: 'APPROVED',
    CorrectionRequest: 'CORRECTIONREQUEST',
};

export const resubmitOptionsList = (options) => {
    if (!options) return [];
    return options.map(({ key, displayValue }) => ({ key, value: displayValue }));
};

export const transformToReasonValue = (reasonKey, options, otherReason) => {
    const foundReason = options?.find((reason) => reasonKey === reason.key);
    if (foundReason && foundReason?.key !== 'Other') {
        return `${foundReason.displayTitle} - ${foundReason.displayValue}`;
    } if (foundReason?.key === 'Other') {
        return `${foundReason.displayTitle} - ${otherReason}`;
    }
    return '';
};
