import React, { useState, useEffect } from 'react';
import {
    Button, Search as SearchIcon, Cross, themeObj,
} from 'tm-library/src';
import { styled } from '@linaria/react';
import { func, string } from 'prop-types';
import ErrorMessage from '../ErrorMessage';
import Input from '../Input';

const Form = styled.form`
    position: relative;
    display:flex;
    max-width:max-content;
    align-items: center;        
`;

const ClearSearch = styled(Cross)`
    position: absolute;
    cursor:pointer;
    width: 14px;
    height: 14px;
    right: 64px;
`;

const SearchField = styled(Input)`
    position: relative;
    display:flex;
    flex-direction: row;
    align-items: center;
    height:45px;
    p{
        padding:16px;
        white-space: nowrap;
    }
    input{
        margin: 0px;
        border-right: 0px;
    }
`;
const SearchButton = styled(Button)`
    margin: 0px;
    height:51px;
    width:51px;
    min-width: unset;
    padding: 0px;
    border-radius:0px;
`;
const Error = styled(ErrorMessage)`
    text-align: end;
`;

const isValidInput = (input) => {
    // is numerical between 1-10 char length
    const validation = /^[0-9]{1,10}$/;
    return validation.test(input) || input === '';
};

const Search = ({ clearSearch, searchTerm, setSearchTerm }) => {
    const [searchField, setSearchField] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if (searchTerm === '') setSearchField('');
    }, [searchTerm]);

    useEffect(() => {
        if (error) setError('');
        if (searchField === '' && searchTerm !== '') clearSearch();
    }, [searchField]);

    const handleChange = (event) => {
        const inputValue = event.target.value;
        if (!isValidInput(inputValue)) return;
        setSearchField(inputValue);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (searchField === '') return;
        if (searchField.length !== 10) {
            setError('Please enter a valid customer CIF number (10-digit value)');
        } else {
            setSearchTerm(searchField);
        }
    };

    return (
        <div>
            <Form onSubmit={handleSubmit}>
                <SearchField
                    id="searchCif"
                    labelText="Search CIF"
                    input="text"
                    maxLength="10"
                    isFullWidth={false}
                    value={searchField}
                    onChange={handleChange}
                />
                {searchField !== '' && <ClearSearch color={themeObj.colors.primary} onClick={() => { setSearchField(''); clearSearch(); }} />}
                <SearchButton data-testid="searchButton" type="submit">
                    <SearchIcon />
                </SearchButton>
            </Form>
            {error && <Error>{error}</Error>}
        </div>

    );
};

export default Search;

Search.propTypes = {
    clearSearch: func.isRequired,
    searchTerm: string,
    setSearchTerm: func.isRequired,
};

Search.defaultProps = {
    searchTerm: '',
};
