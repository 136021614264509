import React from 'react';
import { themeObj } from '../../GlobalStyle';

export default ({ color = themeObj.colors.primary }) => (
    <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="19.5" cy="19.5" r="19.5" fill="#F6F7FA" />
        <g clipPath="url(#clip0_50_844)">
            <path d="M10 19.943V19.3659C10.053 18.9046 10.0809 18.4391 10.1617 17.9828C10.5737 15.6214 11.6904 13.6653 13.5199 12.1643C15.7541 10.3331 18.3138 9.66651 21.1391 10.1567C23.4505 10.5578 25.3904 11.6906 26.8224 13.5783C29.317 16.8643 29.6886 20.453 27.8384 24.1525C26.3951 27.032 23.9854 28.6847 20.8459 29.2029C20.476 29.2643 20.0992 29.2853 19.7256 29.3229H19.2404C19.1816 29.3109 19.1223 29.3019 19.0625 29.2961C17.4825 29.2252 16.0028 28.7923 14.6556 27.9534C12.1994 26.4281 10.6934 24.2147 10.1646 21.3249C10.0801 20.8714 10.053 20.4039 10 19.943ZM27.2316 19.6544C27.2316 15.2941 23.7598 11.7562 19.483 11.7566C15.2063 11.757 11.7357 15.2957 11.7353 19.6553C11.7349 24.0148 15.2083 27.5543 19.4838 27.5539C23.7594 27.5535 27.232 24.0131 27.2316 19.654V19.6544Z" fill={color} />
            <path d="M18.6266 17.0127C18.6266 16.1351 18.6185 15.2574 18.6294 14.3797C18.6292 14.2336 18.6653 14.0898 18.7344 13.9617C18.8034 13.8336 18.9031 13.7254 19.0242 13.6473C19.1453 13.5691 19.2838 13.5234 19.4268 13.5145C19.5699 13.5056 19.7128 13.5338 19.8423 13.5964C20.1965 13.7671 20.3429 14.0676 20.3417 14.4622C20.3376 15.8539 20.3542 17.2461 20.3303 18.6378C20.3243 18.994 20.4189 19.1766 20.7431 19.3287C21.5966 19.729 22.4303 20.1739 23.2696 20.6067C23.6376 20.7959 23.828 21.1426 23.7815 21.5042C23.7006 22.1366 23.0857 22.4676 22.5112 22.1794C21.7025 21.7742 20.9008 21.3603 20.0962 20.9497C19.8019 20.7997 19.5116 20.6401 19.2124 20.5016C18.7956 20.3087 18.6136 19.9908 18.6213 19.5246C18.6347 18.6881 18.625 17.8517 18.625 17.0152L18.6266 17.0127Z" fill={color} />
        </g>
        <defs>
            <clipPath id="clip0_50_844">
                <rect width="19" height="19.322" fill="white" transform="translate(10 10)" />
            </clipPath>
        </defs>
    </svg>
);
