import { themeObj, H2 } from 'tm-library';
import { styled } from '@linaria/react';

export const Background = styled.div`
    background-color: white;
    padding:38px;
    margin: 38px;
    min-width: min-content;
`;

export const TopSection = styled.div`
    display:flex;
    justify-content: space-between;
`;

export const Title = styled(H2)`
    display: flex;
    align-items: center;
    svg{
        padding-right:12px;
    }
`;

export const EmptyList = styled(H2)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin:auto;
    width:100%;
    min-height:600px;
    border: 1px solid ${themeObj.colors.greys.light};
`;
