import { css } from '@linaria/core';
import { createTheming } from '@callstack/react-theme-provider';
import 'Assets/fonts/fonts.css';

const themeObj = {
    colors: {
        primaryLight: '#3ea7da',
        primary: '#0078B2',
        primaryDark: '#00608E',
        secondary: '#EFF2F9',
        white: '#FFFFFF',
        black: '#222222',
        red: '#B20000',
        green: '#2EA903',
        greys: {
            lightest: '#F6F7FA',
            light: '#E4E4E4',
            regular: '#C5C5C5',
            dark: '#8A8A8A',
            darkest: '#474747',
        },
    },
    fonts: {
        regular: 'BrandonText-Regular',
        medium: 'BrandonText-Medium',
        light: 'BrandonText-Light',
    },
    breakpoints: {
        mobile: 375,
        tablet: 768,
        laptop: 1024,

    },
};

const { ThemeProvider, withTheme, useTheme } = createTheming(themeObj);

const globals = css`
    :global() {
        html, body {
            margin: 0; 
            padding: 0;
            height: 100%;
            width: 100%;
            background: #F6F7FA !important;
            max-width: 100%;
            font-family: BrandonText-Regular, Arial, sans-serif;
            color: #474747;
        }
        
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
            h1,h2,h3,h4,h5,h6,p {
                background: none !important;
                -webkit-text-fill-color: inherit !important;
            }
        }
    }
`;

export {
    themeObj, globals, ThemeProvider, withTheme, useTheme,
};
