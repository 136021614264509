import React, { useEffect, useState } from 'react';
import {
    Body, H2, AlignCenter,
} from 'tm-library';
import { styled } from '@linaria/react';
import getFileById from '../../apis/getFileById';

const StyledImg = styled.img`
    max-width: 100%;
`;

const UploadedFile = ({ fileId }) => {
    const [imgData, setImgData] = useState();
    const [hasError, setHasError] = useState(false);
    useEffect(() => {
        getFileById(fileId)
            .then((data) => {
                setImgData(data);
            })
            .catch(() => {
                setHasError(true);
            });
    }, []);
    if (imgData) {
        return (
            <AlignCenter padding="60px">
                <StyledImg alt={`file-${fileId}`} src={`data:image/png;base64,${imgData.data}`} />
                <Body>Right click the image to download</Body>
            </AlignCenter>

        );
    }
    if (hasError) {
        return <AlignCenter padding="60px"><Body>No file found</Body></AlignCenter>;
    }
    return <AlignCenter padding="60px"><H2>Loading...</H2></AlignCenter>;
};

export default UploadedFile;
