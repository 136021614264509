/* eslint-disable camelcase */
/* global DD_RUM */
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { string, func } from 'prop-types';
import formatDateTime from '../../utils/formateDateTime';
import getSubmissionById from '../../apis/getSubmissionById';
import putSubmissionReview from '../../apis/putSubmissionReview';
import SubmissionDetailDisplay from '../../components/SubmissionDetailDisplay';
import { transformToReasonValue, reasonLookup } from '../../utils/resubmissionLookup';

const SubmissionDetail = ({ onBackClick, submissionId }) => {
    const [fileList, setFileList] = useState({
        files: [],
        doesFileListHaveErrors: false,
    });
    const [searchParams] = useSearchParams();
    const [previousTab] = useState(searchParams.get('previousTab'));
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submission, setSubmission] = useState({});
    const [hasAlreadyBeenSubmitted, setHasAlreadyBeenSubmitted] = useState(false);

    useEffect(() => {
        navigate({
            search: '',
        });
    }, []);
    useEffect(() => {
        getSubmissionById(submissionId)
            .then((data) => {
                const {
                    file_ids, infected_file_ids, cifkey, forename, surname, document_request_id, document_type, sub_document_type, created_date_time, review_outcome, review_outcome_reason, reviewer, review_outcome_other_reason,
                } = data;
                const [date, time] = formatDateTime(created_date_time);
                const files = [];
                file_ids.forEach((fileId, i) => {
                    files.push({
                        id: fileId, name: `file${i + 1}`, index: i, hasError: infected_file_ids?.filter((id) => id === fileId).length > 0,
                    });
                });
                setFileList({
                    ...fileList,
                    files,
                    doesFileListHaveErrors: infected_file_ids?.length > 0,
                });
                const reason = transformToReasonValue(review_outcome_reason, reasonLookup, review_outcome_other_reason);
                setSubmission({
                    cif: cifkey,
                    fullName: `${forename} ${surname}`,
                    requestId: document_request_id,
                    date,
                    time,
                    documentType: document_type.toLowerCase() === 'other'
                        ? `${document_type} (${sub_document_type})`
                        : `${document_type}`,
                    documentReview: {
                        outcome: review_outcome.toUpperCase(),
                        reason,
                        reviewer,
                    },
                });
            });
    }, []);

    const reviewSubmission = (outcome, reason, otherReason) => {
        setIsSubmitting(true);
        const userId = localStorage.getItem('userId');
        const documentReviewBody = {
            reviewer: userId,
            review_outcome: outcome.toUpperCase(),
            review_outcome_reason: reason,
            review_outcome_other_reason: otherReason,
        };
        DD_RUM.addAction('OpenFile', documentReviewBody);
        putSubmissionReview(submissionId, documentReviewBody)
            .then(({
                reviewer, review_outcome, review_outcome_reason, review_outcome_other_reason,
            }) => {
                setIsSubmitting(false);
                setSubmission({
                    ...submission,
                    documentReview: {
                        outcome: review_outcome?.toUpperCase(),
                        reason: transformToReasonValue(review_outcome_reason, reasonLookup, review_outcome_other_reason),
                        reviewer,
                    },
                });
            })
            .catch((err) => {
                setIsSubmitting(false);
                setSubmission({
                    ...submission,
                    documentReview: {
                        outcome: '',
                        reason: '',
                        reviewer: '',
                    },
                });
                if (err.message === '{"error":"Submission already reviewed","status":400}') {
                    setHasAlreadyBeenSubmitted(true);
                }
            });
    };

    const toPrevious = () => onBackClick(previousTab);
    return <SubmissionDetailDisplay submissionDetail={submission} fileList={fileList} onBackClick={toPrevious} reviewSubmission={reviewSubmission} isSubmitting={isSubmitting} hasAlreadyBeenSubmitted={hasAlreadyBeenSubmitted} />;
};
export default SubmissionDetail;

SubmissionDetail.protoType = {
    submissionId: string.isRequired,
    onBackClick: func.isRequired,
};
