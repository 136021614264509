import axios from 'axios';
import qs from 'qs';

const getTokens = (code) => {
    const body = {
        grant_type: 'authorization_code',
        code,
        client_id: process.env.REACT_APP_ADMIN_CLIENT_ID,
        redirect_uri: process.env.REACT_APP_ADMIN_REDIRECT_URI,
        scope: 'email openid document-exchange/read document-exchange/write',
    };
    return axios.post(process.env.REACT_APP_OAUTH_TOKEN, qs.stringify(body), {
        headers: {
            'content-type': 'application/x-www-form-urlencoded',
        },
    });
};

export default getTokens;
