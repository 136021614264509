import { useState, useEffect } from 'react';
import {
    arrayOf, shape, string,
} from 'prop-types';

const filterByCif = (list, searchTerm) => {
    if (!list.length || !searchTerm) return list;
    return list.filter(({ customerCIF }) => customerCIF.includes(searchTerm));
};

const useSearch = (list) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredList, setFilteredList] = useState([]);

    const clearSearch = () => {
        setSearchTerm('');
    };

    useEffect(() => {
        if (list?.length && searchTerm) {
            setFilteredList(filterByCif(list, searchTerm));
        }
    }, [list, searchTerm]);

    return {
        searchTerm,
        setSearchTerm,
        filteredList,
        clearSearch,
    };
};

useSearch.propTypes = {
    list: arrayOf(shape({
        customerCIF: string,
    })),
};

useSearch.defaultProps = {
    list: [],
};
export default useSearch;
