import { styled } from '@linaria/react';
import { themeObj } from '../GlobalStyle';

export default styled.a`
    color: ${themeObj.colors.primary};
    cursor: pointer;
    text-decoration: underline;
    font-size: 16px;
    font-family: ${themeObj.fonts.regular};
`;
