import React from 'react';
import { styled } from '@linaria/react';
import { themeObj, SmallBody } from 'tm-library';
import {
    string, func, bool,
} from 'prop-types';
import ErrorMessage from '../ErrorMessage';

const StyledLabel = styled.label`
    display: flex;
    flex-direction: column;
    width: ${(props) => (props.isFullWidth ? '100%' : '')};
`;
const LabelText = styled(SmallBody)`
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    margin-top: 4px;
`;

const StyledInput = styled.input`
    margin-top: 8px;
    font-family: ${themeObj.fonts.regular};
    font-size: 16px;
    border: 2px solid ${themeObj.colors.greys.light};
    height: 45px;
    color: ${themeObj.colors.greys.darkest};
    width: ${(props) => (props.inputWidth ? props.inputWidth : '')};
`;

const Input = ({
    id, labelText, placeholder, value, onChange, onFocus, inputName, type, maxLength, error, inputWidth, isFullWidth, className, ...rest
}) => (
    <StyledLabel htmlFor={id} isFullWidth={isFullWidth} className={className}>
        <LabelText>{labelText}</LabelText>
        <StyledInput
            id={id}
            onChange={onChange}
            onFocus={onFocus}
            value={value}
            placeholder={placeholder}
            name={inputName}
            type={type}
            maxLength={maxLength}
            inputWidth={inputWidth}
            {...rest}
        />

        {error && <ErrorMessage>{error}</ErrorMessage>}
    </StyledLabel>
);

Input.propTypes = {
    id: string.isRequired,
    labelText: string.isRequired,
    isFullWidth: bool,
    onChange: func,
    inputName: string,
    type: string,
    placeholder: string,
    error: string,
};

Input.defaultProps = {
    onChange: undefined,
    isFullWidth: true,
    inputName: 'text',
    type: 'text',
    placeholder: undefined,
    error: undefined,
};

export default Input;
