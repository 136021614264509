import React from 'react';
import { string } from 'prop-types';

const Chevron = ({ color, ...rest }) => (
    <svg
        width="11"
        height="8"
        viewBox="0 0 11 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <path
            d="M5.28363 8L0.449402 0.5L10.1179 0.499999L5.28363 8Z"
            fill={color}
        />
    </svg>
);

export default Chevron;

Chevron.propTypes = {
    color: string,
};

Chevron.defaultProps = {
    color: '#0078B2',
};
