/* eslint-disable react/forbid-prop-types */
// eslint-disable-next-line import/no-cycle
import React from 'react';
import { styled } from '@linaria/react';
import { cx } from '@linaria/core';
import { themeObj } from 'tm-library';
import {
    arrayOf, bool, object, string,
} from 'prop-types';

const ListWrapper = styled.div``;

const ListHeading = styled.p`
    background-color:${themeObj.colors.white};
    font-weight:bold;
    padding: 16px;
`;

const StyledList = styled.ul`
    display: grid;
    grid-template-rows: repeat(5, 0fr);
    grid-column-gap: 5vw;
    grid-auto-flow: column;
    list-style: none;
    padding: 0;
    margin: 0;
    
    @media (min-width: ${themeObj.breakpoints.laptop}px) {
        grid-template-columns: repeat(3, 1fr);
    }
`;

const ListItem = styled.li`
    border-left: 1px solid ${themeObj.colors.greys.light}; 
    border-right: 1px solid ${themeObj.colors.greys.light};    
    &:nth-child(odd) { background-color:${themeObj.colors.greys.lightest};}
    &:nth-child(even) { background-color:${themeObj.colors.white};}
    padding: 16px;
    white-space: nowrap; 
    text-align: left;
    &.list-item--top{
        border-top: 1px solid ${themeObj.colors.greys.light};    
    }
    &.list-item--bottom{
        border-bottom: 1px solid ${themeObj.colors.greys.light};    
    }
    
    &.error {
        border-color: ${themeObj.colors.red};
        border-width: 1px;
        border-style: solid;
        border-left-width: 8px;
    }
    
    svg{
        margin-right:8px;
    }
`;

const List = ({
    listHeading, body, listHasError, ListItemContent,
}) => (
    <ListWrapper>
        {listHeading && (
            <ListHeading id="list-heading">
                {listHeading}
            </ListHeading>
        )}
        {body?.length > 0
            && (
                <StyledList aria-labelledby="list-heading">
                    {body.map((listItem, i) => {
                        const isTop = (i % 5) === 0;
                        const isBottom = (((i + 1) % 5) === 0) || (i + 1) === body.length;
                        const classes = cx(isTop && 'list-item--top', isBottom && 'list-item--bottom', listItem.hasError && 'error');
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <ListItem key={`${listItem.name}-${listItem.id}`} className={classes}>
                                <ListItemContent listItem={listItem} listHasError={listHasError} />
                            </ListItem>
                        );
                    })}
                </StyledList>
            )}
    </ListWrapper>
);

export default List;

List.propTypes = {
    body: arrayOf(object),
    listHeading: string,
    listHasError: bool,
};

List.defaultProps = {
    body: [],
    listHeading: '',
    listHasError: false,
};
