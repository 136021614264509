/* eslint-disable react/destructuring-assignment */
import React from 'react';
import NavLink from '../../components/NavLink';

export default (markdown) => {
    const markdownRegex = /^\[(...+|)\]\((?!javascript:)\S+\)$/i;
    if (!markdownRegex.test(markdown)) return null;

    const hrefRegex = /\((.*?)\)/;
    const previewRegex = /\[(.*?)\]/;

    const [, href] = markdown.match(hrefRegex);
    const [, previewText] = markdown.match(previewRegex);
    return <NavLink href={href}>{previewText || href}</NavLink>;
};
