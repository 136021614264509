import React from 'react';

export default () => (
    <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="19.5" cy="19.5" r="19.5" fill="#F6F7FA" />
        <g clipPath="url(#clip0_15_297)">
            <path d="M27.1111 32.003H12.8889C12.3882 32.0022 11.9082 31.7986 11.5541 31.4368C11.2001 31.075 11.0008 30.5846 11 30.0729V8.93011C11.0008 8.41846 11.2001 7.928 11.5541 7.56621C11.9082 7.20442 12.3882 7.00081 12.8889 7H23.1691C23.3199 7.00053 23.4649 7.05936 23.5749 7.16465L28.806 12.1134C28.8662 12.1697 28.9145 12.2379 28.9478 12.314C28.9812 12.39 28.9989 12.4722 29 12.5556V30.0668C29.0004 30.3207 28.9518 30.5722 28.8571 30.8069C28.7624 31.0416 28.6234 31.2549 28.448 31.4347C28.2726 31.6145 28.0642 31.7572 27.8348 31.8547C27.6055 31.9523 27.3595 32.0026 27.1111 32.003ZM12.8889 8.21966C12.7045 8.21966 12.5277 8.29451 12.3973 8.42775C12.2669 8.56098 12.1936 8.74169 12.1936 8.93011V30.0729C12.1936 30.2614 12.2669 30.4421 12.3973 30.5753C12.5277 30.7085 12.7045 30.7834 12.8889 30.7834H27.1111C27.2952 30.7826 27.4716 30.7075 27.6019 30.5744C27.7321 30.4414 27.8056 30.2611 27.8064 30.0729V12.8269L22.9363 8.21966H12.8889Z" fill="#474747" />
            <path d="M28.4032 13.1654H23.1691C23.0108 13.1654 22.859 13.1011 22.7471 12.9868C22.6351 12.8724 22.5723 12.7173 22.5723 12.5556V7.60983C22.5726 7.49134 22.6068 7.37552 22.6705 7.27654C22.7343 7.17756 22.8249 7.0997 22.9312 7.05247C23.0376 7.00525 23.1551 6.99071 23.2694 7.01063C23.3837 7.03056 23.4899 7.08408 23.5749 7.16465L28.806 12.1134C28.8921 12.1969 28.9518 12.3047 28.9776 12.4231C29.0034 12.5414 28.994 12.6649 28.9508 12.7778C28.9076 12.8907 28.8324 12.9879 28.7348 13.0569C28.6373 13.126 28.5218 13.1637 28.4032 13.1654ZM23.7659 11.9457H26.8783L23.7659 9.00634V11.9457Z" fill="#474747" />
            <path d="M19.8241 14.1838H13.9603C13.8021 14.1838 13.6503 14.1195 13.5383 14.0052C13.4264 13.8908 13.3635 13.7357 13.3635 13.5739C13.3635 13.4122 13.4264 13.2571 13.5383 13.1427C13.6503 13.0284 13.8021 12.9641 13.9603 12.9641H19.8241C19.9824 12.9641 20.1342 13.0284 20.2461 13.1427C20.358 13.2571 20.4209 13.4122 20.4209 13.5739C20.4209 13.7357 20.358 13.8908 20.2461 14.0052C20.1342 14.1195 19.9824 14.1838 19.8241 14.1838Z" fill="#474747" />
            <path d="M24.4046 17.8549H13.9603C13.8021 17.8549 13.6503 17.7907 13.5383 17.6763C13.4264 17.5619 13.3635 17.4068 13.3635 17.2451C13.3635 17.0833 13.4264 16.9282 13.5383 16.8139C13.6503 16.6995 13.8021 16.6353 13.9603 16.6353H24.4046C24.5629 16.6353 24.7147 16.6995 24.8267 16.8139C24.9386 16.9282 25.0015 17.0833 25.0015 17.2451C25.0015 17.4068 24.9386 17.5619 24.8267 17.6763C24.7147 17.7907 24.5629 17.8549 24.4046 17.8549Z" fill="#474747" />
            <path d="M23.0021 21.5295H13.9603C13.8021 21.5295 13.6503 21.4652 13.5383 21.3509C13.4264 21.2365 13.3635 21.0814 13.3635 20.9196C13.3635 20.7579 13.4264 20.6028 13.5383 20.4884C13.6503 20.3741 13.8021 20.3098 13.9603 20.3098H23.0021C23.1604 20.3098 23.3122 20.3741 23.4241 20.4884C23.5361 20.6028 23.5989 20.7579 23.5989 20.9196C23.5989 21.0814 23.5361 21.2365 23.4241 21.3509C23.3122 21.4652 23.1604 21.5295 23.0021 21.5295Z" fill="#474747" />
            <path d="M24.4046 25.2004H13.9603C13.8021 25.2004 13.6503 25.1361 13.5383 25.0218C13.4264 24.9074 13.3635 24.7523 13.3635 24.5905C13.3635 24.4288 13.4264 24.2737 13.5383 24.1593C13.6503 24.045 13.8021 23.9807 13.9603 23.9807H24.4046C24.5629 23.9807 24.7147 24.045 24.8267 24.1593C24.9386 24.2737 25.0015 24.4288 25.0015 24.5905C25.0015 24.7523 24.9386 24.9074 24.8267 25.0218C24.7147 25.1361 24.5629 25.2004 24.4046 25.2004Z" fill="#474747" />
        </g>
        <defs>
            <clipPath id="clip0_15_297">
                <rect width="18" height="25" fill="white" transform="translate(11 7)" />
            </clipPath>
        </defs>
    </svg>
);
