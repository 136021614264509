/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import { styled } from '@linaria/react';
import {
    H2, Table, Body, List, themeObj,
} from 'tm-library';
import { SubmissionDetail as SubmissionDetailIcon, Warning as WarningIcon, Customer as CustomerIcon } from 'tm-library/src/components/Icons/';
import Button from '../Button';
import Dropdown from '../Dropdown';
import ReviewOutcome from '../ReviewOutcome';
import Input from '../Input';
import UploadedFileItem from '../UploadedFileItem';
import { reviewOutcomes, reasonLookup, resubmitOptionsList } from '../../utils/resubmissionLookup';

const Wrapper = styled.div`
    background-color: white;
    padding:38px;
    margin: 38px;
    display: grid;
    grid-gap: 3vh;
`;

const Title = styled(H2)`
    display: flex;
    align-items: center;
    svg{
        padding-right:12px;
    }
`;

const Customer = styled(Body)`
    display: flex;
    align-items: center;
    svg{
        padding-right:12px;
    }
`;

const SubTitle = styled.div`
    display: flex;
    align-items: center;
    min-width: 360px;
    gap: 2%;
`;

const DetailHeader = styled.div`
    display:grid;
    grid-template-columns: 576px auto auto;
`;

const StyledButton = styled(Button)`
    width: 166px;
    height: 53px;
`;
const ResubmitButton = styled(StyledButton)`
    margin-left: 26px;
`;
const ButtonWrapper = styled.div`
    grid-column: 3 / span 1;
    align-self: center; 
    justify-self: right;
`;

const DetailTable = styled(Table)`
    th:first-child,
    td:first-child {
        max-width: 80px;
    }
    th:nth-last-child(2),
    td:nth-last-child(2),
    th:nth-last-child(3),
    td:nth-last-child(3) {
        text-align: left;
    }

    th:nth-last-child(2),
    td:nth-last-child(2){
        min-width:120px;
    }
`;

const Resubmit = styled.div`
    display: grid;
    grid-template-areas: '. .'
                        'input input ';
`;

const InputWrapper = styled.div`
    grid-area: input;
    padding-top: 16px;
`;

const DetailActions = styled.div`
    display:grid;
    grid-template-columns: 166px auto 166px;
    grid-template-rows:  auto auto;
    justify-content: space-between;
    margin-top: 75px;
`;
const ErrorBox = styled.div`
    grid-column: 1 / 4;
    border: 1px solid ${themeObj.colors.red}; 
    color: ${themeObj.colors.red};
    padding: 14px;
    margin-top: 25px;
    display: flex;
    align-items: center;

`;
const ErrorText = styled(Body)`
    font-size: 16px;
    margin: auto;
`;

const ErrorTitle = styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
    svg{
        padding-right:12px;
    }
`;

const DropdownWidth = styled.div`
    min-width: 245px;
`;

const Divider = styled.span`
    padding: 0 12px;
`;

const SubmissionDetailDisplay = ({
    submissionDetail, fileList, onBackClick, reviewSubmission, isSubmitting, hasAlreadyBeenSubmitted,
}) => {
    const [resubmissionReason, setResubmissionReason] = useState();
    const [otherResubmissionReason, setOtherResubmissionReason] = useState();
    const [resubmissionReasonIsInvalid, setResubmissionReasonIsInvalid] = useState(true);
    const validateReason = (reason) => {
        const reasonIsInvalid = !reason || reason === 'Other' || reason.length < 2;
        if (reasonIsInvalid !== resubmissionReasonIsInvalid) setResubmissionReasonIsInvalid(reasonIsInvalid);
    };
    const handleResubmitReasonChange = (option) => {
        const { key, value } = option;
        validateReason(value);
        setResubmissionReason({
            key, value,
        });
        if (otherResubmissionReason) setOtherResubmissionReason();
    };
    const handleInputChange = (event) => {
        const { value } = event.target;
        validateReason(value);
        setOtherResubmissionReason(value);
    };

    const headings = ['Date received', 'Time', 'Document type', 'Reviewer', 'Request Id'];
    const { doesFileListHaveErrors, files } = fileList;
    const {
        fullName, cif, date, time, requestId, documentType, documentReview,
    } = submissionDetail;
    const reviewer = documentReview?.reviewer ? documentReview.reviewer : '-';
    const submissionDetailTableBody = [{
        date, time, documentType, reviewer, requestId,
    }];
    return (
        <Wrapper>
            <DetailHeader>
                <Title>
                    <SubmissionDetailIcon />
                    {' '}
                    Submission details
                </Title>
                <ButtonWrapper>
                    <StyledButton onClick={() => onBackClick()} variant="secondary">Back</StyledButton>
                </ButtonWrapper>
            </DetailHeader>
            <SubTitle>
                <Customer>
                    <CustomerIcon />
                    <strong>{fullName}</strong>
                    <Divider>|</Divider>
                    {cif}
                </Customer>
                {documentReview?.outcome && (
                    <ReviewOutcome outcome={documentReview.outcome} reason={documentReview.reason} />
                )}
            </SubTitle>
            <DetailTable headings={headings} body={submissionDetailTableBody} />
            <List listHeading="Uploaded files" body={files} listHasError={doesFileListHaveErrors} ListItemContent={UploadedFileItem} />
            <DetailActions>
                {(documentReview && !documentReview?.outcome) && (
                    <>
                        <StyledButton
                            disabled={isSubmitting}
                            value="reject"
                            isLoading={isSubmitting && event.target.innerHTML === 'Reject'}
                            variant="reject"
                            onClick={() => reviewSubmission(reviewOutcomes.Rejected)}
                        >
                            Reject
                        </StyledButton>
                        <Resubmit>
                            <DropdownWidth>
                                <Dropdown value={resubmissionReason ? resubmissionReason.value : 'Reason for resubmission'} optionList={resubmitOptionsList(reasonLookup)} onItemSelect={handleResubmitReasonChange} />
                            </DropdownWidth>
                            {resubmissionReason?.value === 'Other' && <InputWrapper><Input id="other" labelText="*Other - Specify reason for resubmission" onChange={handleInputChange} value={otherResubmissionReason} inputName="other" type="text" maxLength={20} inputWidth="245px" /></InputWrapper>}
                            <ResubmitButton
                                disabled={isSubmitting || resubmissionReasonIsInvalid}
                                isLoading={(isSubmitting && event.target.innerHTML === 'Resubmit')}
                                onClick={() => reviewSubmission(reviewOutcomes.CorrectionRequest, resubmissionReason.key, otherResubmissionReason)}
                            >
                                Resubmit
                            </ResubmitButton>
                        </Resubmit>
                        <StyledButton
                            disabled={isSubmitting}
                            isLoading={(isSubmitting && event.target.innerHTML === 'Approve')}
                            variant="approve"
                            onClick={() => reviewSubmission(reviewOutcomes.Approved)}
                        >
                            Approve
                        </StyledButton>
                    </>
                )}
                {doesFileListHaveErrors && (
                    <ErrorBox>
                        <ErrorTitle>
                            <WarningIcon />
                            <strong>Action required</strong>
                        </ErrorTitle>
                        <ErrorText>
                            Corrupt/virus files - Before taking any actions, please contact information security
                        </ErrorText>
                    </ErrorBox>
                )}
                {hasAlreadyBeenSubmitted && (
                    <ErrorBox>
                        <ErrorTitle>
                            <WarningIcon />
                            <strong>Action required</strong>
                        </ErrorTitle>
                        <ErrorText>
                            This submission has been updated by another agent - please refresh and recheck that all the information is correct!
                        </ErrorText>
                    </ErrorBox>
                )}
            </DetailActions>
        </Wrapper>
    );
};

export default SubmissionDetailDisplay;
