/* eslint-disable camelcase */
import React, { useState } from 'react';
import { styled } from '@linaria/react';
import { H2 } from 'tm-library/src';
import Input from '../../components/Input';
import Dropdown from '../../components/Dropdown';
import Button from '../../components/Button';
import ErrorMessage from '../../components/ErrorMessage';
import {
    isInputValid, validateLength,
} from './validations';
import formErrors from '../../actions/formErrors';
import postNewDocumentRequest from '../../apis/postNewDocumentRequest';

const FullWidth = styled.div`
    width: 100%;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 18px;
`;

const StyledButton = styled(Button)`
    margin-left: 0;
    margin-top: 19px;
`;

const NewDocumentRequestForm = ({ onSuccess }) => {
    const [documentRequestDetails, setDocumentRequestDetails] = useState({
        cif: {
            value: '',
            error: '',
        },
        forename: {
            value: '',
            error: '',
        },
        surname: {
            value: '',
            error: '',
        },
        dob: {
            value: '',
            error: '',
        },
        postcode: {
            value: '',
            error: '',
        },
        email: {
            value: '',
            error: '',
        },
        other: {
            value: '',
            error: '',
        },
        error: false,
    });

    const [docType, setDocType] = useState({
        key: '',
        value: '',
        error: '',
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const formErrorsCount = (currentKey) => {
        let formErrorsAmount = formErrors(documentRequestDetails, currentKey).length;
        if (currentKey !== 'docType' && docType.error) {
            formErrorsAmount += 1;
        }
        return formErrorsAmount;
    };

    const handleInputChange = (event) => {
        const { name, value, maxLength } = event.target;
        if (isInputValid(name, value, maxLength)) {
            setDocumentRequestDetails({
                ...documentRequestDetails,
                [name]: { value, error: '' },
                error: formErrorsCount(name) > 0,
            });
        }
    };

    const handleDateChange = (event) => {
        const { name, value } = event.target;
        const invalidChars = /[^0-9 -]/gi;
        const sanitisedValue = value.replace(invalidChars, '');
        setDocumentRequestDetails({
            ...documentRequestDetails,
            [name]: { value: sanitisedValue, error: '' },
            error: formErrorsCount(name) > 0,
        });
    };

    const handleDocTypeChange = (option) => {
        const { key, value } = option;
        setDocType({
            key, value, error: '',
        });
        setDocumentRequestDetails({
            ...documentRequestDetails,
            other: { value: '', error: '' },
            error: formErrorsCount('docType') > 0,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        const {
            cif,
            forename,
            surname,
            dob,
            postcode,
            email,
            other,
        } = documentRequestDetails;

        const cifLengthError = validateLength('cif', cif.value);
        const forenameLengthError = validateLength('forename', forename.value);
        const surnameLengthError = validateLength('surname', surname.value);
        const dobLengthError = validateLength('dob', dob.value);
        const postcodeLengthError = validateLength('postcode', postcode.value);
        const emailLengthError = validateLength('email', email.value);
        const docTypeError = docType.value.length ? '' : 'Please select a document type';
        let otherLengthError;
        if (docType.key === 'other') otherLengthError = validateLength('other', other.value);
        if (cifLengthError || forenameLengthError || surnameLengthError || dobLengthError || postcodeLengthError || emailLengthError || docTypeError || otherLengthError) {
            const newDocumentRequestDetails = { ...documentRequestDetails };
            const newDocType = { ...docType };
            newDocumentRequestDetails.cif.error = cifLengthError;
            newDocumentRequestDetails.forename.error = forenameLengthError;
            newDocumentRequestDetails.surname.error = surnameLengthError;
            newDocumentRequestDetails.dob.error = dobLengthError;
            newDocumentRequestDetails.postcode.error = postcodeLengthError;
            newDocumentRequestDetails.email.error = emailLengthError;
            newDocType.error = docTypeError;
            newDocumentRequestDetails.other.error = otherLengthError;
            newDocumentRequestDetails.error = true;
            setDocumentRequestDetails(newDocumentRequestDetails);
            setDocType(newDocType);
            setIsSubmitting(false);
        } else {
            const dobArr = dob.value.split('-');
            const ddmmyyyy = dobArr.reverse().join('');
            const body = {
                document_type: docType.value,
                sub_document_type: other.value,
                recipient: {
                    cifkey: cif.value,
                    forename: forename.value,
                    surname: surname.value,
                    date_of_birth: ddmmyyyy,
                    postcode: postcode.value,
                    email_address: email.value,
                },
            };
            postNewDocumentRequest(body)
                .then((res) => {
                    const requestId = res?.request_id;
                    setIsSubmitting(false);
                    onSuccess(requestId);
                });
        }
    };
    const documentOptions = [{
        key: 'bereavement', value: 'Bereavement',
    }, {
        key: 'power of attorney', value: 'Power of Attorney',
    }, {
        key: 'change of details', value: 'Change of Details',
    }, {
        key: 'other', value: 'Other',
    },
    ];

    const date = new Date();
    date.setDate(date.getDate() - 1);
    const [yesterdayISO] = date.toISOString().split('T');

    return (
        <Form onSubmit={handleSubmit}>
            <H2>New request</H2>
            <Input
                id="cif"
                labelText="Enter customer CIF number"
                onChange={handleInputChange}
                value={documentRequestDetails.cif.value}
                inputName="cif"
                type="text"
                maxLength={10}
                error={documentRequestDetails.cif.error}
            />

            <Input
                id="forename"
                labelText="Forename"
                onChange={handleInputChange}
                value={documentRequestDetails.forename.value}
                inputName="forename"
                type="text"
                maxLength={40}
                error={documentRequestDetails.forename.error}
            />
            <Input
                id="surname"
                labelText="Surname"
                onChange={handleInputChange}
                value={documentRequestDetails.surname.value}
                inputName="surname"
                type="text"
                maxLength={40}
                error={documentRequestDetails.surname.error}
            />
            <Input
                id="dob"
                labelText="Date of birth"
                onChange={handleDateChange}
                value={documentRequestDetails.dob.value}
                placeholder="Date of birth"
                inputName="dob"
                type="date"
                isFullWidth={false}
                error={documentRequestDetails.dob.error}
                min="1900-01-01"
                max={yesterdayISO}
                onFocus={handleDateChange}
            />
            <Input
                id="postcode"
                labelText="Postcode"
                onChange={handleInputChange}
                value={documentRequestDetails.postcode.value}
                inputName="postcode"
                type="text"
                maxLength={10}
                error={documentRequestDetails.postcode.error}
            />
            <Input
                id="email"
                labelText="Email address"
                onChange={handleInputChange}
                value={documentRequestDetails.email.value}
                inputName="email"
                type="text"
                maxLength={60}
                error={documentRequestDetails.email.error}
            />
            <FullWidth><Dropdown listTitle="Document type" optionList={documentOptions} onItemSelect={handleDocTypeChange} value={docType.value ? docType.value : 'Please select...'} /></FullWidth>
            {docType.error && <ErrorMessage>{docType.error}</ErrorMessage>}
            {docType.key === 'other' && <Input id="other" labelText="Enter ‘other’ document type" onChange={handleInputChange} value={documentRequestDetails.other.value} inputName="other" type="text" maxLength={20} error={documentRequestDetails.other.error} />}
            <StyledButton isLoading={isSubmitting}>Create Request</StyledButton>
            {documentRequestDetails.error && <ErrorMessage>Errors found! Please review</ErrorMessage>}
        </Form>
    );
};

export default NewDocumentRequestForm;
