/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useState } from 'react';
import { styled } from '@linaria/react';
import { cx } from '@linaria/core';
import { string, func, array } from 'prop-types';
import { themeObj, Chevron, SmallBody } from 'tm-library';

const DropdownWrapper = styled.div`
    background-color: ${themeObj.colors.greys.lightest};
    border: 1px solid rgba(34, 34, 34, 0.1);
    border-radius: 5px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
    color: ${themeObj.colors.greys.dark};
    font-family: ${themeObj.fonts.regular};
    letter-spacing: -0.5px;
    line-height: 24px;
    position: relative;
    text-align: left;
    &:focus {
        outline: none;
    }
    &:disabled {
        background-color: ${themeObj.colors.greys.light};
    }
`;
const Header = styled.div``;
const HeaderTitle = styled(SmallBody)`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:  3px 15px;
    position: relative;
    margin: 0;
    height: 45px;
    font-size: 16px;
`;
const ListWrapper = styled.ul`
    background-color: ${themeObj.colors.greys.lightest};
    border: 1px solid rgba(34, 34, 34, 0.1);
    border-radius: 5px;
    left: -1px;
    letter-spacing: -0.5px;
    line-height: 24px;
    list-style: none;
    margin-top: 0px;
    max-height: 15vh;
    overflow-y: scroll;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
    padding: 0;
    position: absolute;
    text-align: left;
    top: 50px;
    width: 100%;
    z-index: 1;
`;
const ListItem = styled.li`
    padding: 10px 0 10px 20px;
    position: relative;
    p {
        margin: 0;
    }
    :hover {
        background-color: ${themeObj.colors.greys.light};
    }
`;
const StyledChevron = styled(Chevron)`
    position: relative;
    &.open {
        transform: rotate(-180deg);
    }
`;

const ListTitle = styled(SmallBody)`
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    margin-top: 4px;
    margin-bottom: 8px;
`;

const Dropdown = ({
    value, onItemSelect, optionList, listTitle,
}) => {
    const [isListOpen, setIsListOpen] = useState(false);
    const styledChevronClasses = cx(isListOpen && 'open');
    return (
        <>
            {listTitle && <ListTitle>{listTitle}</ListTitle>}
            <DropdownWrapper data-testid="dropdown-comp">
                <Header
                    tabIndex="1"
                    role="listbox"
                    onClick={() => {
                        setIsListOpen(!isListOpen);
                    }}
                >
                    <HeaderTitle
                        data-testid="dropdown-box"
                        value={value}
                    >
                        {value}
                        <StyledChevron className={styledChevronClasses} />
                    </HeaderTitle>
                </Header>
                {isListOpen && (
                    <ListWrapper data-testid="dropdown-list">
                        {optionList.map((option) => (
                            <ListItem
                                role="option"
                                key={option.key}
                                onClick={() => {
                                    onItemSelect(option);
                                    setIsListOpen(!isListOpen);
                                }}
                            >
                                {' '}
                                <p>
                                    {option.value}
                                </p>
                            </ListItem>
                        ))}
                    </ListWrapper>
                )}
            </DropdownWrapper>
        </>
    );
};

Dropdown.propTypes = {
    value: string,
    onItemSelect: func.isRequired,
    optionList: array.isRequired,
};

Dropdown.defaultProps = {
    value: '',
};

export default Dropdown;
