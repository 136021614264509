import React from 'react';
import { styled } from '@linaria/react';
import { H1 } from 'tm-library/src';

const StyledHeader = styled(H1)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

const ErrorView = () => <StyledHeader>Oops, Something went wrong!</StyledHeader>;

export default ErrorView;
