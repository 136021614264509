import { styled } from '@linaria/react';
import React from 'react';
import { Body, themeObj } from 'tm-library';
import { string } from 'prop-types';
import { reviewOutcomes } from '../../utils/resubmissionLookup';

const Outcome = styled(Body)`
    font-size:16px;
    margin: 0;
    font-family: ${themeObj.fonts.medium};
`;

const RejectedOutcome = styled(Outcome)`
    color: ${themeObj.colors.red};
`;

const ApprovedOutcome = styled(Outcome)`
    color: ${themeObj.colors.green};
`;

const ReviewOutcome = ({ outcome, reason }) => {
    if (outcome === reviewOutcomes.Approved) {
        return (
            <ApprovedOutcome>{outcome}</ApprovedOutcome>
        );
    } if (outcome === reviewOutcomes.Rejected) {
        return (
            <RejectedOutcome>{`${outcome}`}</RejectedOutcome>
        );
    } if (outcome === reviewOutcomes.CorrectionRequest) {
        return (
            <RejectedOutcome>{`REJECTED | ${reason}`}</RejectedOutcome>
        );
    }
    return (
        <RejectedOutcome>{`${outcome} | ${reason}`}</RejectedOutcome>
    );
};

ReviewOutcome.propTypes = {
    outcome: string.isRequired,
    reason: string,
};
ReviewOutcome.defaultProps = {
    reason: '',
};

export default ReviewOutcome;
