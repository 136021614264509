import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { string } from 'prop-types';
import { datadogRum } from '@datadog/browser-rum';

const Head = ({ title, packageName }) => {
    useEffect(() => {
        datadogRum.init({
            applicationId: process.env.REACT_APP_DATADOG_RUM_APPLICATION_ID,
            clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
            site: 'datadoghq.eu',
            service: `${process.env.REACT_APP_DATADOG_RUM_SERVICE}-${packageName}`,
            sampleRate: process.env.REACT_APP_DATADOG_RUM_SAMPLE_RATE / 1,
            trackInteractions: true,
            env: `${process.env.REACT_APP_DATADOG_RUM_ENV}`,
        });
    }, []);

    return (
        <Helmet>
            <meta charset="UTF-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <link rel="stylesheet" type="text/css" href="main.styles.css" />
            <title>{title}</title>
        </Helmet>
    );
};

export default Head;

Head.propTypes = {
    title: string.isRequired,
    packageName: string.isRequired,
};
