export const inputRegex = {
    cif: /^\d+$/,
    forename: /^[A-Z -]*$/i,
    surname: /^[A-Z -]*$/i,
    postcode: /\w/,
    email: /./,
    other: /./,
};

export const isInputValid = (name, value, maxLength) => (value.length <= maxLength && inputRegex[name]?.test(value)) || (value.length === 0);

export const validationRules = {
    cif: {
        emptyMessage: 'Please enter a customer CIF number',
        isValidRegex: /^[\d]{10}$/,
        isValidMessage: 'Please enter a valid customer CIF number (10-digit value)',
    },
    forename: {
        emptyMessage: 'Please enter a customer forename',
        isValidRegex: /^[A-Z][A-Z -]*[A-Z]{2,40}$/i,
        isValidMessage: 'Please enter a valid forename (from 2 to 40 characters)',
    },
    surname: {
        emptyMessage: 'Please enter a customer surname',
        isValidRegex: /^[A-Z][A-Z -]*[A-Z]{2,40}$/i,
        isValidMessage: 'Please enter a customer surname (from 2 to 40 characters)',
    },
    dob: {
        emptyMessage: 'Please enter a date of birth',
        isValidRegex: /.{8}/,
        isValidMessage: 'Please enter a valid date of birth',
    },
    postcode: {
        emptyMessage: 'Please enter a postcode',
        isValidRegex: /^[A-Z0-9 ]{2,10}$/i,
        isValidMessage: 'Please enter a valid postcode (from 2 to 10 characters)',
    },
    email: {
        emptyMessage: 'Please enter an email address',
        isValidRegex: /^([!#$%&'*+\-/=?^_`{|}~A-Za-z0-9]+\.)*[!#$%&'*+\-/=?^_`{|}~A-Za-z0-9]+@(([A-Za-z0-9]+-)*[A-Za-z0-9]+\.)*([A-Za-z0-9]+-)*[A-Za-z0-9]*[A-Za-z][A-Za-z0-9]*$/,
        isValidMessage: 'Please enter an email address in the format name@example.com',
    },
    other: {
        emptyMessage: 'Please enter ‘other’ document type',
        isValidRegex: /.{2,20}/,
        isValidMessage: 'Please enter a valid ‘other’ document type (from 2 to 20 characters)',
    },
};

export const validateLength = (inputKey, value) => {
    if (value === '') {
        return validationRules[inputKey].emptyMessage;
    }
    if (!validationRules[inputKey].isValidRegex.test(value)) {
        return validationRules[inputKey].isValidMessage;
    }
    if (inputKey === 'dob') {
        const dob = new Date(value);
        const today = new Date().setHours(0, 0, 0, 0);
        if (dob >= today) {
            return 'Date of birth cannot be today or a future date';
        }
    }
    return null;
};
