import React from 'react';

export default () => (
    <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_504_447)">
            <path d="M10.4932 18.9966C7.57439 18.9966 4.65596 18.9966 1.73789 18.9966C1.27483 18.9966 0.860053 18.8845 0.518795 18.5574C0.257506 18.3131 0.0833183 17.9869 0.0237133 17.6304C-0.0358916 17.2738 0.0224776 16.9071 0.189607 16.5881C0.384925 16.2196 0.606583 15.8656 0.811777 15.5015C1.1256 14.9414 1.43393 14.3813 1.74886 13.8212C2.46429 12.5516 3.18119 11.282 3.89955 10.0124C5.62888 6.95051 7.35859 3.89119 9.08865 0.834495C9.22363 0.572501 9.428 0.354502 9.67848 0.205358C9.92895 0.0562136 10.2154 -0.0180716 10.5053 -0.00902817C11.1318 -0.00902817 11.6026 0.292311 11.9164 0.845698L16.4361 8.83733L20.0144 15.1643C20.2701 15.6124 20.5214 16.0706 20.7825 16.5198C20.9197 16.7635 20.9934 17.039 20.9967 17.3201C21.0001 17.6012 20.9329 17.8784 20.8015 18.1255C20.6702 18.3726 20.4791 18.5812 20.2464 18.7315C20.0137 18.8818 19.7473 18.9688 19.4724 18.9842C19.3846 18.9842 19.2968 18.9842 19.209 18.9842L10.4932 18.9966ZM10.4932 17.8293C13.4328 17.8293 16.3736 17.8293 19.3132 17.8349C19.4049 17.8416 19.4966 17.8219 19.5779 17.7781C19.6592 17.7343 19.7268 17.6681 19.7731 17.587C19.8194 17.5059 19.8424 17.4132 19.8397 17.3194C19.837 17.2256 19.8086 17.1345 19.7576 17.0564C19.3429 16.3685 18.9588 15.6594 18.5638 14.9593L15.5539 9.63716L12.3443 3.96102C11.8725 3.12758 11.4014 2.29376 10.931 1.45957C10.7039 1.05966 10.2847 1.05742 10.0532 1.45957C9.50453 2.4308 8.95589 3.40539 8.40724 4.37214C7.39261 6.17195 6.37468 7.96804 5.35346 9.76038C5.12303 10.1659 4.90358 10.577 4.67424 10.9837C3.77007 12.5856 2.86481 14.1867 1.95844 15.7871L1.22216 17.0888C1.17699 17.1582 1.15099 17.2388 1.14694 17.322C1.14288 17.4052 1.1609 17.488 1.19911 17.5616C1.30226 17.7666 1.47674 17.8271 1.69071 17.8271C4.62707 17.8263 7.56305 17.8259 10.4987 17.8259L10.4932 17.8293Z" fill="#B20000" />
            <path d="M10.309 5.01074C11.4414 5.01074 12.1448 5.89011 11.9638 6.83221C11.854 7.39904 11.7553 7.96699 11.6609 8.53606C11.4327 9.9087 11.207 11.2813 10.9839 12.654C10.9762 12.6988 10.974 12.7436 10.9685 12.7873C10.9597 12.9056 10.9088 13.0166 10.8253 13.0992C10.7418 13.1818 10.6315 13.2302 10.5153 13.2354C10.3998 13.2386 10.2872 13.1985 10.1987 13.1228C10.1101 13.0471 10.0518 12.9409 10.0347 12.8243C9.73404 10.9789 9.43302 9.13389 9.13163 7.28926C9.10639 7.13915 9.0647 6.9924 9.03946 6.84118C8.985 6.58865 8.99691 6.32591 9.07399 6.0796C9.15107 5.8333 9.29059 5.61218 9.47838 5.43867C9.76916 5.15637 10.1038 5.01186 10.309 5.01074Z" fill="#B20000" />
            <path d="M10.5021 17.0822C10.3484 17.0834 10.1959 17.0536 10.0535 16.9945C9.91104 16.9354 9.78143 16.8482 9.67205 16.7379C9.56267 16.6276 9.47569 16.4964 9.41609 16.3517C9.35649 16.207 9.32544 16.0517 9.32473 15.8948C9.32386 15.7359 9.35365 15.5784 9.41241 15.4313C9.47116 15.2842 9.55773 15.1503 9.66716 15.0373C9.77659 14.9244 9.90676 14.8345 10.0502 14.7729C10.1937 14.7113 10.3476 14.6791 10.5032 14.6782C10.6577 14.6792 10.8104 14.7114 10.9527 14.7728C11.095 14.8342 11.224 14.9236 11.3324 15.036C11.4407 15.1484 11.5263 15.2815 11.5842 15.4277C11.642 15.5739 11.6711 15.7304 11.6696 15.8881C11.6702 16.0449 11.6404 16.2002 11.582 16.3452C11.5235 16.4902 11.4376 16.622 11.3291 16.733C11.2206 16.844 11.0917 16.9319 10.9498 16.9919C10.8078 17.0518 10.6557 17.0825 10.5021 17.0822Z" fill="#B20000" />
        </g>
        <defs>
            <clipPath id="clip0_504_447">
                <rect width="21" height="19" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
