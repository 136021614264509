export default (requests) => {
    if (!requests) return [];

    const sorted = requests.sort((firstItem, secondItem) => {
        const firstDate = new Date(firstItem?.created_date_time);
        const secondDate = new Date(secondItem?.created_date_time);

        if (firstDate.toString() === 'Invalid Date') return 1;
        if (secondDate.toString() === 'Invalid Date') return -1;

        return secondDate - firstDate;
    });
    return sorted;
};
