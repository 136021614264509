// @flow
import React from 'react';
import { styled } from '@linaria/react';
import {
    node, string, bool, func,
} from 'prop-types';
import { themeObj } from 'tm-library/src/components/GlobalStyle';
import LibButton from 'tm-library/src/components/Button';

const StyledButton = styled(LibButton)`
    min-width:92px;
    padding: 14px 23px;
    margin: 0;
    position: static;
    &.reject {
            background-color: ${themeObj.colors.red};
    }
    &.approve {
            background-color: ${themeObj.colors.green};
    }
    
    &.loading,
    &:disabled,
    &[disabled] {
         background-color: ${themeObj.colors.greys.light};
         color: ${themeObj.colors.greys.dark};
         &:hover,active{
             background-color: ${themeObj.colors.greys.light};
             color: ${themeObj.colors.greys.dark};
         }
     }
     &.secondary {
         border: 1px solid ${themeObj.colors.greys.darkest};
         background-color: ${themeObj.colors.white};
         color: ${themeObj.colors.greys.darkest};
         &:hover{
             background-color: ${themeObj.colors.white};
             color: ${themeObj.colors.greys.darkest};
         }
         &:active{
             background-color: ${themeObj.colors.white};
             color: ${themeObj.colors.greys.darkest};
         }
     }
    `;

const Button = ({
    theme,
    children, onClick, variant, disabled, isLoading, className, ...rest
}) => (
    <StyledButton
        onClick={onClick}
        variant={variant}
        disabled={disabled}
        isLoading={isLoading}
        className={className}
        {...rest}
    >
        {children}
    </StyledButton>
);

export default Button;

Button.propTypes = {
    children: node.isRequired,
    variant: string,
    disabled: bool,
    isLoading: bool,
    onClick: func,
};

Button.defaultProps = {
    variant: 'primary',
    disabled: false,
    isLoading: false,
    onClick: undefined,
};
