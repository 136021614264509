import React from 'react';
import { string } from 'prop-types';

export const Cross = ({ color, ...rest }) => (
    <svg
        width="32px"
        height="32px"
        viewBox="0 0 32 32"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        {...rest}
    >
        <g
            id="Symbols"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
        >
            <g
                id="Header_nav"
                transform="translate(-327.000000, -16.000000)"
                fill={color}
            >
                <g id="Header">
                    <g
                        id="Group-2"
                        transform="translate(16.000000, 16.000000)"
                    >
                        <g
                            id="Group"
                            transform="translate(311.000000, 0.000000)"
                        >
                            <path
                                d="M5.76496806,4.30282065 L16,14.538 L26.2350319,4.30282065 C26.6077343,3.93011831 27.1941913,3.9014489 27.5997789,4.21681241 L27.6971794,4.30282065 C28.1009402,4.70658151 28.1009402,5.3612072 27.6971794,5.76496806 L27.6971794,5.76496806 L17.462,16 L27.6971794,26.2350319 C28.1009402,26.6387928 28.1009402,27.2934185 27.6971794,27.6971794 C27.2934185,28.1009402 26.6387928,28.1009402 26.2350319,27.6971794 L16,17.462 L5.76496806,27.6971794 C5.39226573,28.0698817 4.80580874,28.0985511 4.40022108,27.7831876 L4.30282065,27.6971794 C3.89905978,27.2934185 3.89905978,26.6387928 4.30282065,26.2350319 L4.30282065,26.2350319 L14.538,16 L4.30282065,5.76496806 C3.89905978,5.3612072 3.89905978,4.70658151 4.30282065,4.30282065 C4.70658151,3.89905978 5.3612072,3.89905978 5.76496806,4.30282065 Z"
                                id="Combined-Shape"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);
export default Cross;

Cross.propTypes = {
    color: string,
};

Cross.defaultProps = {
    color: '#FFFFFF',
};
