import React from 'react';
import { themeObj } from '../../GlobalStyle';

export default ({ color = themeObj.colors.primary }) => (
    <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="19.5" cy="19.5" r="19.5" fill="#F6F7FA" />
        <path d="M10.0005 20.4084C10.0483 20.2988 10.1034 20.1915 10.1421 20.0788C10.8405 18.046 11.5351 16.0119 12.2259 13.9763C12.2878 13.793 12.3712 13.7348 12.5597 13.7398C13.0885 13.7531 13.6178 13.7444 14.1876 13.7444C13.4401 15.9446 12.7027 18.1192 11.9526 20.33H12.2555C14.1406 20.33 16.0258 20.3327 17.911 20.3254C18.105 20.3254 18.1756 20.3712 18.1793 20.5752C18.1884 20.7804 18.2202 20.984 18.274 21.1821C18.5291 22.2208 19.1995 22.5385 20.0959 22.494C21.138 22.4427 21.6654 21.9045 21.7811 20.8557C21.7998 20.6903 21.8266 20.5262 21.8503 20.3355H28.0447L25.8083 13.7458C26.4323 13.7458 27.0203 13.7407 27.6083 13.7545C27.6594 13.7545 27.7281 13.8727 27.7541 13.9479C28.2888 15.4988 28.8202 17.0505 29.3482 18.6032C29.3751 18.6821 29.3983 18.7632 29.432 18.8393C29.9003 19.9032 30.0269 21.012 29.995 22.1727C29.9444 24.0232 29.9813 25.8755 29.9822 27.7274C29.9822 27.819 29.9904 27.9107 29.995 28.0024H10L10.0005 20.4084ZM11.8164 26.1689H28.1722V22.1488C28.1443 22.1423 28.116 22.1377 28.0875 22.1351C26.5426 22.1351 24.9976 22.131 23.4527 22.1401C23.3761 22.1401 23.28 22.2318 23.2249 22.3029C22.9726 22.6508 22.7799 23.0569 22.4784 23.3521C21.629 24.1831 20.5709 24.4476 19.42 24.2739C18.2134 24.0905 17.297 23.4653 16.7814 22.3216C16.7118 22.1672 16.6279 22.1296 16.474 22.13C14.9969 22.1346 13.5203 22.1328 12.0437 22.1333C11.9703 22.1333 11.897 22.141 11.8159 22.1456L11.8164 26.1689Z" fill={color} />
        <path d="M15.4878 13.7408L16.7075 12.5193L19.0304 14.8676L19.0928 14.8378V9.00024H20.8951V14.8772L23.2453 12.5041L24.5106 13.7551L20.0015 18.2848L15.4878 13.7408Z" fill={color} />
    </svg>
);
