import axiosClient from '../apiClient';
import redirectOn401 from '../../utils/redirectOn401';
/* global DD_RUM */

export default (submissionId, body) => axiosClient(localStorage.access_token).put(`/submissions/${submissionId}/review`, body)
    .then(({ data }) => data)
    .catch((err) => {
        DD_RUM.addAction('putSubmissionReviewError', { err });
        redirectOn401(err.response.status);
        throw Error(JSON.stringify(err.response.data));
    });
