/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { styled } from '@linaria/react';
import { Table, NavLink } from 'tm-library';
import { Inbox as InboxIcon } from 'tm-library/src/components/Icons/';
import formatDateTime from '../../utils/formatDateTime';
import {
    Background, Title, TopSection, EmptyList,
} from '../TabScreenTemplate';
import filterAndSortRequests from '../../utils/filterAndSortRequests';
import createCifLinkMarkdown from '../../utils/createCifLinkMarkdown';
import Search from '../../components/Search';
import useSearch from '../../hooks/useSearch';

const InboxTable = styled(Table)`
    th:nth-child(n+4):nth-child(-n+5),
    td:nth-child(n+4):nth-child(-n+5) {
        text-align: left;
    }
`;

export const modelInboxTableSubmissions = (submissions) => {
    if (!submissions) return [];
    const modelledSubmissions = [];
    submissions.forEach((submission) => {
        const {
            document_request_id, submission_id, created_date_time, document_type, sub_document_type, cifkey, forename, surname,
        } = submission;
        const [date, time] = formatDateTime(created_date_time);
        const rootUrl = window.location.href.split('?')[0];

        const documentType = document_type.toLowerCase() === 'other'
            ? `${document_type} (${sub_document_type})`
            : `${document_type}`;

        const modelledSubmission = {
            dateRequested: date,
            time,
            customerCIF: createCifLinkMarkdown(cifkey, `${rootUrl}?submissionId=${submission_id}&previousTab=Inbox`),
            customerName: `${forename} ${surname}`,
            documentType,
            requestID: document_request_id,
        };
        modelledSubmissions.push(modelledSubmission);
    });
    return modelledSubmissions;
};

const Inbox = ({ submissions }) => {
    const [inboxList, setInboxList] = useState([]);
    const headings = ['Date received', 'Time', 'Customer CIF', 'Customer name', 'Document type', 'Request ID'];
    useEffect(() => {
        if (!submissions || submissions?.length < 1) return;
        const filteredAndSortedSubmissions = filterAndSortRequests(submissions, 'Verified');
        const modelledSubmissions = modelInboxTableSubmissions(filteredAndSortedSubmissions);
        setInboxList(modelledSubmissions);
    }, [submissions]);

    const {
        searchTerm, setSearchTerm, filteredList, clearSearch,
    } = useSearch(inboxList);

    return (
        <Background>
            <TopSection>
                <Title>
                    <InboxIcon />
                    Inbox
                </Title>
                <Search clearSearch={clearSearch} searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
            </TopSection>
            {searchTerm
                ? (
                    <>
                        <InboxTable headings={headings} body={filteredList} />
                        {filteredList?.length < 1
                            && (
                                <>
                                    <EmptyList>
                                        No results found
                                        <NavLink onClick={clearSearch}>Clear search</NavLink>
                                    </EmptyList>
                                </>
                            )}
                    </>
                )
                : (
                    <>
                        <InboxTable headings={headings} body={inboxList} />
                        {inboxList?.length < 1 && <EmptyList>Your inbox is clear</EmptyList>}
                    </>
                )}
        </Background>
    );
};

export default Inbox;
