import { createRemoteJWKSet, jwtVerify } from 'jose';

const validateToken = (token) => {
    const JWKS = createRemoteJWKSet(new URL(process.env.REACT_APP_AWS_JWKS));
    return jwtVerify(token, JWKS, {
        issuer: process.env.REACT_APP_COGNITO_ISS,
        audience: process.env.REACT_APP_ADMIN_CLIENT_ID,
    });
};

export default validateToken;
